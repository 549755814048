import React from 'react';

export default function LeftSide({ onToggleMenu }) {
	return (
		<div className='floatLeft block'>
			<button
				type='button'
				className='p-link layout-menu-button p-text-left p-mr-3'
				onClick={onToggleMenu}
			>
				<span className='pi pi-bars' />
			</button>
			<a href='/'>
				<img
					className='p-ml-2 height30px p-text-left'
					src='/images/logo.png'
					alt='Logo'
				/>
			</a>
		</div>
	);
}

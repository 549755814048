import { Card } from 'primereact/card';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../store/actions/projectAction';
import { clienteError, monederoTablaError } from '../../configs/mensajes';
import { search } from '../../configs/textos';
import '../../layout/customs/responsiveDatatable.scss';
import Searchbar from '../../components/Searchbar';
import useGetMonederos from './hooks/useGetMonederos';
import MonederosTable from './MonederosTable';

const Clientes = (props) => {
	const {
		global: { networkId },
	} = props;

	const [searchInput, setSearchInput] = useState('');
	const [offset, setOffset] = useState(0);
	const [totalClientes, setTotalClientes] = useState(0);
	const { t } = useTranslation();
	const toast = useRef(null);

	const { data, loading, error } = useGetMonederos({
		offset,
		searchInput,
		networkId,
	});

	if (error) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: clienteError,
			life: 3000,
		});
	}

	const { items: clientes = [], total } = data?.listCust_wallets || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalClientes(total);
		}
	}, [total]);

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-page-title'>{t('listaMonederos')}</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<Searchbar
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
							setOffset(0);
						}}
						placeholder={t(search.placeholderMonedero)}
					/>
					<MonederosTable
						value={clientes}
						loading={data?.listCust_named_customers == null && loading}
						emptyMessage={t(monederoTablaError)}
						rows={10}
						total={totalClientes}
						onPage={onPage}
						first={offset}
						lazy
					/>
				</Card>
			</div>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Clientes));

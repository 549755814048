import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { documentDialog } from '../../configs/textos';
import LoadingSpinner from '../../components/LoadingSpinner';
import getFileURL, { getExtension } from '../../helpers/S3Service';
import File from './File';

export default function ReferenceDocument({ fileUrl }) {
	const { t } = useTranslation();
	const [url, setUrl] = useState(null);
	const extension = fileUrl ? getExtension(fileUrl) : null;
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const initFile = async () => {
			if (fileUrl) {
				const urlToRender = await getFileURL(fileUrl);
				setUrl(urlToRender);
			}
			setLoading(false);
		};

		initFile();
	}, [fileUrl]);

	return (
		<div
			className={classNames('ref-doc-container', {
				'ref-doc-container-column': !fileUrl,
			})}
		>
			{loading && <LoadingSpinner custom />}
			{!loading && url ? (
				<>
					<span
						htmlFor={t(documentDialog.refDoc)}
						className='label-ref-doc p-text-bold p-mt-0'
					>
						<div className='p-d-flex p-ai-center'>
							{t(documentDialog.refDoc)}

							<a
								href={url}
								className='p-datatable-column-button icon-button p-ml-3'
								rel='noreferrer'
								target='_blank'
							>
								<div className='p-d-flex p-ai-center'>
									<i
										className='ri-external-link-fill'
										style={{ fontSize: '23px' }}
									/>
								</div>
							</a>
						</div>
					</span>
					<div className='ref-doc-filefield'>
						<File
							url={url}
							extension={extension}
							showOpenOtherWindowIcon={false}
						/>
					</div>
				</>
			) : (
				<div className='p-d-flex p-flex-column p-jc-center p-ml-1'>
					<div className='label-ref-doc p-mt-0 p-text-bold'>
						{t(documentDialog.refDoc)}
					</div>
					<label className='custom-label'>{documentDialog.notAvailable}</label>
				</div>
			)}
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';

import TextAreaField from '../../../../components/TextAreaField';
import SingleData from '../../DatosClienteTable/SingleData';

import * as projectAction from '../../../../store/actions/projectAction';
import DialogFooter from './DialogFooter';
import { addClass, removeClass } from '../../helpers/Dialog';
import { dialogWalletBalance } from '../../../../configs/textos';
import AmountField from './AmountField';
import ReasonField from './ReasonField';
import validateEmptyFields from '../../../../helpers/ValidateFields';
import translate from '../../../../helpers/Translate';

const DialogUpdateWalletBalance = ({
	open,
	setOpenDialog,
	wallet,
	i18n,
	loading,
	onSubmitUpdateWallet,
	customerId,
	errors,
	setSaldoError,
}) => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(open);
	const [confirmState, setConfirmState] = useState(false);
	const [finalBalance, setFinalBalance] = useState(0);
	const [operationBalance, setOperationBalance] = useState('');

	function onSubmit(data) {
		const { amount } = data;
		onSubmitUpdateWallet({
			...data,
			amount: Number(amount),
			customer_id: customerId,
			wallet_id: wallet?.wallet_id,
			wallet_def_id: wallet?.wallet_def_id,
		});
	}

	function restoreDialogAsDefault() {
		setDisplayBasic(false);
		setOpenDialog(false);
	}

	useEffect(() => {
		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);

	const validate = (data) => {
		if (!Number(data.amount)) {
			return 'error';
		}
		return (
			validateEmptyFields(
				['description', 'amount', 'wallet_transaction_type_id'],
				data
			) || 'error'
		);
	};

	function calculateFinalBalance(amount, currentBalance) {
		return operationBalance === 1
			? Number(currentBalance) - Number(amount)
			: Number(currentBalance) + Number(amount);
	}

	function header() {
		if (!confirmState && !errors) {
			return t(dialogWalletBalance.modifyBalance);
		}
		if (confirmState && !errors) {
			return t(dialogWalletBalance.confirm);
		}
		if (errors) {
			return t(dialogWalletBalance.fail);
		}
	}

	return (
		<>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit, hasValidationErrors, form, values }) => (
					<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
						<Dialog
							header={header}
							visible={displayBasic}
							style={{ width: '500px' }}
							onShow={() => {
								setConfirmState(false);
								setSaldoError(null);
								form.reset({});
							}}
							onHide={() => {
								restoreDialogAsDefault();
								setConfirmState(false);
								setSaldoError(null);
								form.reset({});
							}}
							footer={
								<DialogFooter
									disabled={hasValidationErrors}
									onClickSaveHandler={() => {
										setConfirmState(true);
										setFinalBalance(
											calculateFinalBalance(
												values?.amount,
												wallet?.current_units_balance
											).toFixed(2)
										);
									}}
									onClickAcceptHandler={handleSubmit}
									onClickModifyHandler={() => {
										setConfirmState(false);
										setSaldoError(null);
									}}
									onClickCancelHandler={() => {
										restoreDialogAsDefault(form);
										setConfirmState(false);
										setSaldoError(null);
										form.reset({});
									}}
									loading={loading}
									error={errors}
									confirm={confirmState}
								/>
							}
						>
							<div className='card'>
								<div className='p-ml-4 p-mr-5'>
									<div className='font16 p-margin'>
										<div className='p-grid p-jc-center p-flex-column'>
											<div className='p-col-12 p-mb-4 p-py-0 p-px-2 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogWalletBalance.wallet)}
													info={
														wallet?.name
															? `${
																	JSON.parse(wallet.name)[translate(i18n)]
															  } (...${`${wallet?.wallet_serial_number}${wallet?.check_digit}`.slice(
																	-4
															  )})`
															: '-'
													}
												/>
											</div>
											<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
												<SingleData
													label={t(dialogWalletBalance.balance)}
													info={`${Number(
														wallet?.current_units_balance
													).toFixed(2)} ${wallet?.unit_type_short_name}`}
												/>
											</div>
											<ReasonField
												i18n={i18n}
												setOperationBalance={setOperationBalance}
												confirmState={confirmState}
												reason={values?.wallet_transaction_type_id || null}
											/>
											<AmountField
												confirmState={confirmState}
												amount={Number(values?.amount).toFixed(2)}
												wallet={wallet}
											/>
											{confirmState && (
												<div className='p-col-12 p-mb-4 p-px-2 p-py-0 p-d-flex p-flex-column p-flex-lg-row'>
													<SingleData
														label={t(dialogWalletBalance.finalBalance)}
														info={`${finalBalance} ${wallet?.unit_type_short_name}`}
													/>
												</div>
											)}
											{!confirmState && (
												<TextAreaField
													id='description'
													label={t(dialogWalletBalance.description)}
													placeholder={t(
														dialogWalletBalance.descriptionPlaceholder
													)}
												/>
											)}
										</div>
										{confirmState && !errors && (
											<div className='p-text-center p-m-3'>
												{t(dialogWalletBalance.confirmQuestion)}
											</div>
										)}
										{errors && (
											<div className='p-text-center p-error error-balance p-pt-2'>
												{errors}
											</div>
										)}
									</div>
								</div>
							</div>
						</Dialog>
					</form>
				)}
			/>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DialogUpdateWalletBalance));

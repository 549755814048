import gql from 'graphql-tag';

export const getClientes = gql`
	query getClientes($input: ListCust_named_customerInput) {
		listCust_named_customers(input: $input) {
			total
			items {
				customer_id
				smart_id
			}
		}
	}
`;

export const getMonederos = gql`
	query getMonederos($input: ListCust_walletInput) {
		listCust_wallets(input: $input) {
			total
			items {
				wallet_id
				Cust_pocket {
					customer_id
				}
				exp_date
				init_date
				current_units_balance
				wallet_serial_number
			}
		}
	}
`;

export const getClienteWallet = gql`
	query getClienteWallet($customerId: Int!) {
		listCust_pockets(input: { filter: { customer_id: { eq: $customerId } } }) {
			total
			items {
				archived
				date_add
				customer_id
				pocket_id
				Cust_wallet {
					items {
						wallet_id
						current_units_balance
						alias
						init_flag
						init_date
						exp_date
						wallet_serial_number
						check_digit
					}
				}
			}
		}
		listCom_wallet_transactions(
			input: {
				filter: { customer_id: { eq: $customerId } }
				orderBy: { direction: DESC, field: date_time }
			}
		) {
			items {
				amount
				wallet_def_id
				wallet_transaction_id
				transaction_id
				description
				available_balance
				date_time
				lang
				Com_wallet_transaction_type {
					name
				}
				Com_wallet_transaction_state {
					name
					successful
				}
			}
		}
	}
`;
export const getClienteWalletsCertificadosAndLastMovementsByCustomerId = gql`
	query GetClienteWalletsCertificadosByCustomerIdQuery(
		$customerId: Int!
		$networkId: Int!
	) {
		getCustomerWallets(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			wallets {
				wallet_id
				archived
				blackList {
					Com_wallet_black_list_type {
						name
						wallet_black_list_type_id
					}
					description
					wallet_black_list
					wallet_id
					from_date
				}
				current_units_balance
				exp_date
				init_date
				name
				pocket_name
				state
				unit_type_id
				unit_type_short_name
				wallet_def_id
				wallet_serial_number
				check_digit
			}
		}
		getCustomerCertificates(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			Dem_certificate_category {
				items {
					category_multiple_types
					certificate_category_id
					name
					Dem_certificate_type {
						items {
							c_order
							certificate_type_id
							description
							name
							Cust_named_customer_certificate {
								items {
									exp_date
									init_date
									named_customer_certificate_id
									value
									Cust_named_customer_certificate_operation {
										certificate_denial_description
										timestamp
										Dem_certificate_denial_type {
											name
										}
										Cust_named_customer_certificate_state {
											named_customer_certificate_state_id
											name
											text_color
											web_color
										}
									}
								}
							}
						}
					}
				}
			}
		}
		getCustomerData(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			birth_date
			customer_id
			document_id
			email
			name
			photo
			surname
			tel_code
			tel_code_id
			tel_number
			register_type_id
			blackList {
				Com_customer_black_list_type {
					customer_black_list_type_id
					name
				}
				customer_black_list_id
				description
				from_date
			}
		}
		listCustomerMovements(
			input: {
				filter: {
					customer_id: { eq: $customerId }
					network_id: { eq: $networkId }
				}
				pagination: { limit: 5, offset: 0 }
				orderBy: { direction: DESC, field: date_time }
			}
		) {
			items {
				amount
				name
				wallet_serial_number
				check_digit
				date_time
				available_balance
				linea
				bus
				description
				transaction_id
				unit_type_short_name
				validation_id
				wallet_transaction_id
				wallet_transaction_state_name
				wallet_transaction_type_name
			}
		}
	}
`;

export const getCustomerData = gql`
	query GetCustomerDataQuery($customerId: Int!, $networkId: Int!) {
		getCustomerData(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			birth_date
			customer_id
			document_id
			email
			name
			photo
			surname
			tel_code
			tel_code_id
			tel_number
			blackList {
				Com_customer_black_list_type {
					customer_black_list_type_id
					name
				}
				customer_black_list_id
				description
				from_date
			}
		}
	}
`;

export const getCustomerWallets = gql`
	query GetCustomerWallets($customerId: Int!, $networkId: Int!) {
		getCustomerWallets(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			wallets {
				wallet_id
				archived
				blackList {
					Com_wallet_black_list_type {
						name
						wallet_black_list_type_id
					}
					description
					wallet_black_list
					wallet_id
					from_date
				}
				current_units_balance
				exp_date
				init_date
				name
				pocket_name
				state
				unit_type_id
				unit_type_short_name
				wallet_def_id
				wallet_serial_number
				check_digit
			}
		}
	}
`;

export const getCustomerCertificates = gql`
	query GetCustomerCertificatesQuery($customerId: Int!, $networkId: Int!) {
		getCustomerCertificates(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			Dem_certificate_category {
				items {
					category_multiple_types
					certificate_category_id
					name
					Dem_certificate_type {
						items {
							c_order
							certificate_type_id
							description
							name
							Cust_named_customer_certificate {
								items {
									exp_date
									init_date
									named_customer_certificate_id
									value
									Cust_named_customer_certificate_operation {
										certificate_denial_description
										timestamp
										Dem_certificate_denial_type {
											name
										}
										Cust_named_customer_certificate_state {
											named_customer_certificate_state_id
											name
											text_color
											web_color
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getUserByEmail = gql`
	query MyQuery {
		getSysUser {
			email
			name
			network_id
			operations
			pages
			user_id
			networks {
				network_id
				short_name
			}
		}
	}
`;

export const getSysUserByNetwork = gql`
	query MyQuery($input: GetSys_userInput) {
		getSysUser(input: $input) {
			email
			name
			network_id
			operations
			pages
			user_id
			networks {
				network_id
				short_name
			}
			pocket_defs
			pocket_def_id
		}
	}
`;

export const listTelephonoCode = gql`
	query MyQuery {
		listCust_tel_codes {
			items {
				name
				tel_code_id
				code
			}
		}
	}
`;

export const getCustomerMovements = gql`
	query getCustomerMovements($inputFilter: ListCustomerMovementsInput!) {
		listCustomerMovements(input: $inputFilter) {
			items {
				amount
				name
				wallet_serial_number
				date_time
				available_balance
				linea
				bus
				description
				transaction_id
				unit_type_short_name
				validation_id
				wallet_transaction_id
				wallet_transaction_state_name
				wallet_transaction_type_name
				check_digit
				parada_origen
				parada_destino
				external_id
				parada_id
				fleet_code
				sentido
				conductor
				sodigosa
				validation_method
			}
			total
		}
	}
`;

export const listCustWalletsSerialNumber = gql`
	query listCustWalletsSerialNumber($customerId: Int!, $networkId: Int!) {
		listCustWalletSerialNumbers(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			items {
				wallet_id
				wallet_serial_number
				check_digit
			}
		}
	}
`;

export const listUpdateBalanceReasons = gql`
	query MyQuery {
		listCom_wallet_transaction_types(
			input: { filter: { operable: { eq: true } } }
		) {
			items {
				name
				wallet_transaction_type_id
				Com_wallet_transaction_operation_type {
					wallet_transaction_operation_type_id
				}
			}
		}
	}
`;

export const listTransactionTypes = gql`
	query listTransactionTypes {
		listCom_wallet_transaction_types {
			items {
				name
				operable
				wallet_transaction_type_id
				Com_wallet_transaction_operation_type {
					wallet_transaction_operation_type_id
				}
			}
		}
	}
`;

export const blackListCustomerReasons = gql`
	query MyQuery {
		listCom_customer_black_list_types {
			items {
				customer_black_list_type_id
				name
			}
		}
	}
`;

export const blackListWalletReasons = gql`
	query MyQuery {
		listCom_wallet_black_list_types {
			items {
				wallet_black_list_type_id
				name
			}
		}
	}
`;

export const blackListCustomerUnblockReasons = gql`
	query MyQuery {
		listCom_customer_black_list_unblock_types {
			items {
				customer_black_list_unblock_type_id
				name
			}
		}
	}
`;

export const blackListWalletUnblockReasons = gql`
	query MyQuery {
		listCom_wallet_black_list_unblock_types {
			items {
				wallet_black_list_unblock_type_id
				name
			}
		}
	}
`;

export const getBuses = gql`
	query ListBuses($input: ListBusesInput!) {
		listBuses(input: $input) {
			items {
				bus_id
				machines
				plate
				service_class_name
				vehicle_id
				service_class_id
			}
			total
		}
	}
`;

export const getValidadoras = gql`
	query ListValidadoras($input: ListRes_machineInput!) {
		listRes_machines(input: $input) {
			items {
				mac
				machine_id
				serial_number
				last_sync_keep_alive
				Res_bus_machine(input: { filter: { end_date: { isNull: true } } }) {
					items {
						init_date
						Res_bus {
							vehicle_id
							bus_id
						}
					}
				}
			}
			total
		}
	}
`;

export const listServiceClassNames = gql`
	query ListServiceClassNames($networkId: Int) {
		listCom_service_classs(
			input: { filter: { network_id: { eq: $networkId } } }
		) {
			items {
				service_class_id
				long_name
			}
		}
	}
`;

export const getPocketsDef = gql`
	query getPocketsDef($input: Com_pocket_defInput) {
		listCom_pocket_defs(input: $input) {
			items {
				description
				end_date
				init_date
				name
				pocket_def_id
			}
			total
		}
	}
`;

export const getPocketDefInfo = gql`
	query getPocketDefInfo($pocketDefId: Int!) {
		listCom_pocket_defs(
			input: { filter: { pocket_def_id: { eq: $pocketDefId } } }
		) {
			items {
				description
				end_date
				init_date
				name
				maximum_units
				minimum_units
				Com_wallet_def {
					items {
						description
						name
						end_date
						init_date
						wallet_def_id
					}
				}
			}
		}
	}
`;

export const getUsers = gql`
	query MyQuery($input: ListSys_userInput) {
		listSys_users(input: $input) {
			items {
				email
				name
				user_id
				Sys_user_network(input: { filter: { end_date: { isNull: true } } }) {
					items {
						Sys_role {
							name
							role_id
						}
						Org_network {
							short_name
							network_id
						}
					}
				}
			}
			total
		}
	}
`;

export const listNetworks = gql`
	query MyQuery {
		listOrg_networks {
			items {
				short_name
				network_id
			}
		}
	}
`;

export const listRoles = gql`
	query MyQuery {
		listSys_roles {
			items {
				role_id
				name
			}
		}
	}
`;

export const getWalletDef = gql`
	query getWalletDef($walletDefId: Int!) {
		getWalletDefDetails(input: { wallet_def_id: $walletDefId }) {
			deposit
			description
			end_date
			image
			init_date
			init_method
			initial_recharge
			max_units
			maximum_units
			min_balance_units
			minimum_units
			multiple_units
			name
			period_type
			requisites
			terms_and_conditions
			unit_type
			val_period_count
			wallet_def_id
			certificates {
				description
				name
			}
		}
	}
`;

export const getMovements = gql`
	query getMovements($inputFilter: ListNetworkMovementsInput!) {
		listNetworkMovements(input: $inputFilter) {
			items {
				amount
				name
				wallet_serial_number
				date_time
				available_balance
				linea
				bus
				description
				transaction_id
				unit_type_short_name
				validation_id
				wallet_transaction_id
				wallet_transaction_state_name
				wallet_transaction_type_name
				customer_id
				check_digit
				parada_origen
				parada_destino
				external_id
				parada_id
				fleet_code
				sentido
				conductor
				sodigosa
				validation_method
			}
			total
		}
	}
`;

export const getWalletSerialNumbers = gql`
	query MyQuery($inputFilter: ListWalletSerialNumbersInput!) {
		listNetworkWalletSerialNumbers(input: $inputFilter) {
			items {
				wallet_id
				wallet_serial_number_complete
			}
			total
		}
	}
`;

export const getDocuments = gql`
	query MyQuery($inputFilter: ListCertificatesInput!) {
		listCertificates(input: $inputFilter) {
			total
			items {
				category_multiple_types
				certificate_category_name
				certificate_type_name
				customer_certificate_state_name
				customer_certificate_state_web_color
				customer_certificate_state_text_color
				customer_id
				exp_date
				named_customer_certificate_id
				timestamp
				email
				total_pending
			}
		}
	}
`;

export const listCertificateStates = gql`
	query MyQuery {
		listCust_named_customer_certificate_states(
			input: {
				orderBy: { direction: ASC, field: state_order }
				filter: { visible: { eq: true } }
			}
		) {
			items {
				name
				named_customer_certificate_state_id
			}
		}
	}
`;

export const listCertificateTypes = gql`
	query MyQuery($networkId: Int!) {
		listDem_certificate_types(input: { filter: { network_id: $networkId } }) {
			items {
				category_multiple_types
				certificate_category_name
				certificate_type_id
				name
			}
		}
	}
`;

export const getCertificateDetails = gql`
	query MyQuery($customerCertificateId: Int!) {
		getCustomerCertificateDetails(
			input: {
				filter: { named_customer_certificate_id: $customerCertificateId }
			}
		) {
			category_multiple_types
			exp_date
			init_date
			requires_value
			value
			document_id_url
			Dem_certificate_type {
				certificate_type_id
				name
				certificate_type_id
				Dem_certificate_category {
					name
					certificate_category_id
				}
				Md_period_type {
					milliseconds
				}
				Dem_certificate_file {
					total
				}
				period_count
			}
			Cust_named_customer_certificate_operation {
				certificate_denial_description
				timestamp
				Dem_certificate_denial_type {
					name
				}
				Cust_named_customer_certificate_state {
					name
					named_customer_certificate_state_id
				}
			}
			Cust_named_customer_certificate_file {
				items {
					Dem_certificate_file {
						certificate_file_id
						description
						file_order
						file_type
					}
					file_url
					named_customer_certificate_file_id
				}
			}
		}
	}
`;

export const listDenialTypes = gql`
	query MyQuery($networkId: Int!) {
		listDem_certificate_denial_types(
			input: {
				filter: { network_id: { eq: $networkId } }
				orderBy: { direction: ASC, field: t_order }
			}
		) {
			items {
				certificate_denial_type_id
				name
			}
		}
	}
`;

export const listDocumentTypesByCustomerId = gql`
	query MyQuery($networkId: Int!, $customerId: Int!) {
		listDem_certificate_typesByCustomer(
			input: { filter: { customer_id: $customerId, network_id: $networkId } }
		) {
			items {
				name
				certificate_category_id
				requires_value
				Dem_certificate_type {
					items {
						certificate_type_id
						c_order
						name
						period_count
						network_id
						Dem_certificate_file {
							items {
								certificate_file_id
								description
								file_order
								file_type
							}
						}
						Md_period_type {
							milliseconds
						}
					}
					total
				}
			}
		}
	}
`;

import React from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import Filterbar from './Filterbar';
import { movementTableError } from '../../configs/mensajes';
import { perfilCliente } from '../../configs/textos';
import * as projectAction from '../../store/actions/projectAction';
import { dateFormat } from '../../helpers/DateFormat';
import StateMovement from '../DatosCliente/StateMovement';
import AccountLink from '../Clientes/AccountLink';
import translate from '../../helpers/Translate';

const MovementsTable = (props) => {
	const { movements, i18n, title, onFilter, global, totalRecords, ...restProps } = props;
	const {
		networkId,
		user: { networks },
	} = global;
	const networkName = networks.find(
		(el) => el.network_id === networkId
	)?.short_name;
	const { t } = useTranslation();

	function onFilterHandler(data) {
		onFilter(data);
	}

	return (
		<>
			<Filterbar
				i18n={i18n}
				onFilter={onFilterHandler}
				networkName={networkName}
				networkId={networkId}
				totalRecords={totalRecords}
			/>
			<ResponsiveDataTable
				{...restProps}
				value={movements}
				emptyMessage={t(movementTableError)}
			>
				<Column
					header={t(perfilCliente.movimientos.wallet)}
					body={(row) =>
						row?.name ? JSON.parse(row.name)[translate(i18n)] : '-'
					}
				/>
				<Column
					header={t(perfilCliente.movimientos.serialNumber)}
					body={(row) =>
						row?.wallet_serial_number
							? `${row.wallet_serial_number}${row?.check_digit}`
							: '-'
					}
				/>
				<Column
					header={t(perfilCliente.movimientos.type)}
					body={(row) =>
						row?.wallet_transaction_type_name
							? JSON.parse(row.wallet_transaction_type_name)[translate(i18n)]
							: '-'
					}
				/>
				<Column
					header={t(perfilCliente.movimientos.concept)}
					body={(row) => {
						if (row?.transaction_id) {
							return row.transaction_id;
						}
						if (row?.linea && row?.bus) {
							return `${row.linea} (${row.bus})`;
						}
						if (row?.description) {
							return row.description;
						}
						return '-';
					}}
				/>
				<Column
					header={t(perfilCliente.movimientos.date)}
					body={(row) => dateFormat(row.date_time, 'DD/MM/YYYY HH:mm:ss')}
				/>
				<Column
					header={t(perfilCliente.movimientos.amount)}
					body={(row) =>
						row?.amount ? `${row.amount} ${row?.unit_type_short_name}` : '-'
					}
					className='width120'
				/>
				<Column
					header={t(perfilCliente.movimientos.balance)}
					body={(row) =>
						row?.available_balance
							? `${row.available_balance} ${row?.unit_type_short_name}`
							: '-'
					}
					className='width120'
				/>
				<Column
					header={t(perfilCliente.movimientos.state)}
					body={(row) =>
						row?.wallet_transaction_state_name ? (
							<StateMovement
								transactionStateName={
									JSON.parse(row.wallet_transaction_state_name)[translate(i18n)]
								}
							/>
						) : (
							'-'
						)
					}
					className='width120'
				/>
				<Column
					body={(e) => (
						<AccountLink customerId={e.customer_id} label={t('Ver cliente')} />
					)}
					className='width120'
				/>
			</ResponsiveDataTable>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(MovementsTable));

import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';

const url = require('url');
const aws4 = require('aws4');

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path, errorType }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, ErrorType: ${errorType}`
			)
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const awsGraphqlFetch = (uri, options) => {
	options = options || {};
	options.service = 'appsync';
	const signable = {};
	const urlObject = url.parse(uri);
	signable.host = urlObject.host;
	signable.path = urlObject.path;

	['method', 'body', 'headers', 'region', 'service'].forEach((key) => {
		signable[key] = options[key];
	});

	return Auth.currentCredentials().then((data) => {
		const credentials = {
			secretAccessKey: data.secretAccessKey,
			accessKeyId: data.accessKeyId,
			sessionToken: data.sessionToken,
		};
		aws4.sign(signable, credentials);
		options.headers = signable.headers;
		return fetch(uri, options);
	});
};

// async function currentSession() {
// 	return Auth.currentSession().then((CUS) => CUS.getIdToken().getJwtToken());
// }

/**
 * Resolves authMiddleware link base on AppSync AuthType
 * @param {'API_KEY' | 'COGNITO'} authType
 */
function resolveAuthMiddleware() {
	return new ApolloLink(async (operation, forward) => {
		operation.setContext(({ headers }) => ({
			headers: {
				...headers,
				accept: 'application/json, text/plain, */*',
				'content-type': 'application/json;charset=UTF-8',
			},
		}));

		return forward(operation);
	});
}

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_GRAPHQL_URL,
	fetch: awsGraphqlFetch,
});

const httpLinkSaldo = new HttpLink({
	uri: process.env.REACT_APP_GRAPHQL_URL_SALDO,
	fetch: awsGraphqlFetch,
});

const cache = new InMemoryCache({ addTypename: false });

export const clientAuthApiKey = new ApolloClient({
	link: ApolloLink.from([
		resolveAuthMiddleware('API_KEY'),
		errorLink,
		httpLink,
	]),
	cache,
});

export const clientAuthCognito = new ApolloClient({
	link: ApolloLink.from([
		resolveAuthMiddleware('COGNITO'),
		errorLink,
		httpLink,
	]),
	cache,
});

export const clientAuthIAM = new ApolloClient({
	link: ApolloLink.from([resolveAuthMiddleware('IAM'), errorLink, httpLink]),
	cache,
});

export const clientAuthSaldos = new ApolloClient({
	link: ApolloLink.from([
		resolveAuthMiddleware('IAM'),
		errorLink,
		httpLinkSaldo,
	]),
	cache,
});
